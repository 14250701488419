<template>
    <div class="reset-page-container darken">
        <div class="content">
            <img src="../assets/logo.svg" class="logo " alt="logo"/>

            <Form v-if="screen === 'reset'" @submit="reset" :disabled="is_processing" class="form-container reset-form">

                <p>{{ $t('auth.identity_verified') }}</p>
                <p>{{ $t('auth.set_new_password') }}</p>

                <FormInputPassword v-model="$v.credentials.password.$model" identifier="password"
                                   :placeholder="$t('auth.enter_your_new_password')" :disabled="is_processing" type="password"
                                   :has-error="$v.credentials.password.$error" autocomplete="on">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.password.required">
                            {{$t('validation.x_is_required',{x: $t('users.password')})}}
                        </p>
                        <p v-else-if="!$v.credentials.password.minLength">
                            {{ $t('validation.password_must_be_atleast_x_characters',{x: 8}) }}
                        </p>
                    </template>
                </FormInputPassword>

                <FormInputPassword v-model="$v.credentials.password_confirmation.$model" identifier="password"
                                   :placeholder="$t('auth.retype_password')" :disabled="is_processing" type="password"
                                   :has-error="$v.credentials.password_confirmation.$error" autocomplete="on">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.password_confirmation.required">
                            {{$t('validation.x_is_required',{x: $t('auth.password')})}}
                        </p>
                        <p v-else-if="!$v.credentials.password_confirmation.sameAs">
                            {{$t('validation.password_confirmation_must_match_new_password')}}
                        </p>
                    </template>
                </FormInputPassword>

                <div class="submit-row">
                    <Button :disabled="is_processing" className="--primary" :class="{'spinner-black': is_processing}" type="submit">
                        {{$t('update')}}
                    </Button>
                </div>
            </Form>
            <Form v-else-if="screen === 'reset_success'" class="success-form">
                <p>{{ $t('auth.password_has_been_updated') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="goToLogin">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText.vue";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "reset-page",
    components: {FormInputPassword, FormInputText, Form, Button},
    data() {
        return {
            credentials: {
                password: '',
                password_confirmation: '',
            },
            token: null,
            screen: 'reset',
            show_password: false,
            is_validating: false,
            is_processing: false,
        }
    },
    validations: {
        credentials: {
            password: { required },
            password_confirmation: {
                required: requiredIf(function (u) {
                    return u.password;
                }),
                passwordConfirmation: sameAs('password'),
            },
        }
    },
    methods: {
        reset(){
            this.$v.credentials.$touch();
            if (this.$v.credentials.$anyError || this.is_processing)
                return;

            this.is_processing = true;
            this.$axios
                .post(
                    `auth/password/reset`,
                    {
                        token: this.token,
                        password: this.credentials.password,
                        password_confirmation: this.credentials.password_confirmation,
                    },
                    { headers: { Authorization: null } }
                )
                .then(({ data }) => {
                    this.$notify({
                        text: this.$t('auth.password_has_been_updated'),
                        type: 'success',
                    });

                    this.screen = 'reset_success'

                    this.is_processing = false;
                })
                .catch((e) => {
                    this.is_processing = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
                        type: 'error',
                    });
                });
        },

        goToLogin() {
            this.$router.push({
                name: 'login',
                params: { locale: this.$store.state.locale },
            });
        },

        async validateToken() {
            this.is_validating = true;
            await this.$axios
                .post(
                    `auth/password/reset/validate`,
                    {
                        token: this.token,
                    },
                    { headers: { Authorization: null } }
                )
                .then(({ data }) => {
                    this.is_validating = false;
                })
                .catch((e) => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('auth.unauthorized')),
                        type: 'error',
                    });

                    this.$router.push({
                        name: 'login',
                        params: { locale: this.$store.state.locale },
                    });
                });
        },
    },
    async mounted() {
        // if (this.$route.query.token) {
        //     this.token = this.$route.query.token;
        //     await this.validateToken();
        // } else
        //     this.$router.push({
        //         name: 'login',
        //     });
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.reset_password')
            },
        }
    }
}
</script>
<style lang="scss" scoped>
.reset-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-primary-over min-h-screen w-full flex flex-col justify-center items-center;

    //&.darken {
    //    position: relative;
    //
    //    &:after {
    //        content: '';
    //        position: absolute;
    //        top: 0;
    //        bottom: 0;
    //        left: 0;
    //        right: 0;
    //        display: block;
    //        background-color: rgba(0, 0, 0, 0.38);
    //    }
    //}

    .content {
        @apply px-10 py-8 rounded-lg flex flex-col max-w-full bg-white;

        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm{
            width: 478px;
        }

        .logo {
            @apply w-auto mb-8 object-contain h-8;
        }

        .form-container {
            @apply bg-white;

            & > .input-group{
                margin-bottom: 1rem !important;

                input {
                    color: theme('colors.black') !important;
                    height: 50px;
                }

                .password-field{
                    height: 50px;

                    & > button{
                        @apply mt-1;
                    }
                }
            }

            .submit-row {
                @apply flex flex-row justify-between items-center;
                margin-top: 0rem !important;

                a.forgot {
                    @apply text-black text-sm font-bold no-underline cursor-pointer;
                }
            }
        }

        .reset-form {
            @apply flex flex-col items-center;

            & > p {
                @apply text-black font-medium leading-loose text-base text-center max-w-92;

                &:last-of-type {
                    @apply mb-4;
                }
            }
        }

        .success-form {
            @apply flex flex-col items-center;

            & > p {
                @apply text-black font-medium text-base text-center max-w-92 mt-2;
            }

            svg{
                @apply text-secondary text-8xl my-12;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button{
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }
}
</style>
