var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-container"},[_c('Headbar',{scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('Button',{attrs:{"className":"--primary --small","onclick":_vm.generateDownload,"disabled":_vm.is_printing}},[_vm._v(" "+_vm._s(_vm.$t('sublogs.export_sublogs'))+" ")]),_c('Search',{staticClass:"search-desktop",attrs:{"placeholder":_vm.$t('sublogs.search_sublogs')},on:{"search":_vm.search}}),_c('button',{staticClass:"btn-search-mobile btn-icon-only",on:{"click":function($event){_vm.headbarExpanded = _vm.headbarExpanded === 'search' ? null : 'search'}}},[(_vm.headbarExpanded === 'search')?_c('font-awesome-icon',{attrs:{"icon":['far', 'times']}}):_c('font-awesome-icon',{attrs:{"icon":['far', 'search']}})],1)]},proxy:true},{key:"expanded",fn:function(){return [_c('div',{staticClass:"headbar-expanded-container"},[(_vm.headbarExpanded === 'search')?_c('Search',{attrs:{"placeholder":_vm.$t('sublogs.search_sublogs')},on:{"search":_vm.search}}):_vm._e()],1)]},proxy:true}])}),_c('main',[_c('vue-good-table',{attrs:{"mode":"remote","styleClass":"vgt-table vgt-custom","columns":_vm.columns,"rows":_vm.logs,"isLoading":_vm.is_loading_logs,"search-options":{
            enabled: false,
        },"pagination-options":{
            enabled: true,
            mode: 'records',
            dropdownAllowAll: false,
            perPage: 15,
            perPageDropdownEnabled: false,
            rowsPerPageLabel: _vm.$t('x_per_page', {x: _vm.$t('logs.logs')}),
        },"totalRows":_vm.totalRecords},on:{"update:isLoading":function($event){_vm.is_loading_logs=$event},"update:is-loading":function($event){_vm.is_loading_logs=$event},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'attributes.created_at')?_c('div',[_c('p',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.$moment(props.row.attributes.created_at).format('HH:mm DD-MM-YYYY')))])]):(props.column.field === 'after')?_c('div',{staticClass:"td-after"},[(_vm.$store.getters.hasPermission('destroy logEntries'))?_c('Button',{attrs:{"className":"--secondary --outline --mini --big-text","onclick":()=>_vm.toggleDelete(props.row)}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'trash']}})],1):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),(_vm.csv_data.length)?_c('download-c-s-v',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"csv-generator",attrs:{"data":_vm.csv_data}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }