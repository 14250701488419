<template>
    <div class="input-group" :class="{error: hasError}">
        <label v-if="label" :for="identifier">{{label}}</label>
        <div class="input-container">
            <div class="select-wrapper">
                <Select :id="selectIdentifier" :value="selectValue" className="input" @input="(v)=>$emit('update:selectValue', v)"
                        :placeholder="selectPlaceholder" :disabled="disabled" :options="selectOptions" :multiple="selectMultiple"
                        :close-on-select="closeOnSelect" :allow-empty="selectAllowEmpty" :searchable="false"
                        :custom-label="selectDisplayCustomLabel" :track-by="selectTrackBy" @updated="()=>$emit('updated')" class="multiselect --mini"/>
            </div>
            <input :id="identifier" :value="inputValue" @input="(e)=>$emit('update:inputValue', e.target.value)" :type="type"
                   :placeholder="placeholder" :disabled="disabled" :autocomplete="autocomplete" class="input"
                   :readonly="readonly">
        </div>
        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
import FormErrors from "./FormErrors";
import Select from "./Select";

export default {
    name: "FormInputTextSelect",
    components: {FormErrors, Select},
    props: {
        inputValue: {
            type: [String, Object, Number],
        },
        selectValue: {
            type: [String, Object, Array],
        },
        identifier: {
            type: String,
            default: null,
        },
        selectIdentifier: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        },
        autocomplete: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        useTextarea: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        selectOptions: {
            type: Array,
            default: () => ([])
        },
        selectDisplayCustomLabel: {
            default: null,
        },
        selectTrackBy: {
            type: String,
        },
        selectPlaceholder: {
            type: String,
            default: null
        },
        selectMultiple: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        selectAllowEmpty: {
            type: Boolean,
            default: true,
        },
    }
}
</script>

<style lang="scss" scoped>
.input-group {
    @apply w-full flex flex-col items-start mb-5;

    label {
        @apply text-sm text-grey font-bold mb-3;
    }

    .input-container {
        @apply flex flex-row items-center bg-white border-2 border-grey-light rounded w-full;

        .select-wrapper {
            @apply w-1/5 bg-grey-light;

            .multiselect {
                @apply border-none bg-grey-dark;

            }
        }

        input {
            @apply bg-white text-grey text-sm font-normal rounded w-full py-3 px-5 appearance-none;

            &:focus {
                @apply outline-none;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: theme('colors.black');
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            }

            &:disabled {
                @apply text-grey;
            }
        }

        &:focus-within {
            @apply border-primary;
        }
    }

    ::-webkit-input-placeholder { /* Edge */
        color: theme('colors.grey-dark') !important;
        opacity: 1 !important;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: theme('colors.grey-dark') !important;
        opacity: 1 !important;
    }

    ::placeholder {
        color: theme('colors.grey-dark') !important;
        opacity: 1 !important;
    }

    &.error {
        .input {
            @apply border-negative;
        }
    }
}
</style>