<template>
    <div class="section-header-container">
        <h2>{{title}}</h2>
        <Button v-if="button" className="--primary --outline --mini --wider" :onclick="onclick">{{button}}</Button>
    </div>
</template>

<script>
    import Button from "@/components/Button";

    export default {
        name: "SectionHeader",
        components: {Button},

        props: {
            title: {
                type: String,
                required: true,
            },
            button: {
                type: String,
                required: false,
            },
            onclick: {
                type: Function,
                required: false,
                default: () => ({})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .section-header-container {
          @apply flex flex-row justify-between bg-grey-form text-black py-3 px-8 rounded-t w-full;

          h2 {
            @apply text-grey text-lg font-bold text-left my-auto;
          }
    }
</style>