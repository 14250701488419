<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>

                <div class="filter-container">
                    <p>{{ $t('logs.filter_by') }}</p>
                    <FormInputSelect v-model="filter_player" className="filter" :placeholder="$t('players.players')"
                                     :options="playerOptions" identifier="player" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`"
                                     @input="onFilterUpdated"/>
                </div>
                <div class="filter-container">
                    <p>{{ $t('logs.filter_by') }}</p>
                    <FormInputSelect v-model="filter_game" className="filter" :placeholder="$t('games.game')"
                                     :options="gameOptions" identifier="game" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`"
                                     @input="onFilterUpdated"/>
                </div>
                <Button className="--primary --small" :onclick="generateDownload"
                        :disabled="is_printing">
                  {{ $t('logs.export_logs') }}
                </Button>
                <Search class="search-desktop" :placeholder="$t('logs.search_logs')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('logs.search_logs')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="logs"
                :isLoading.sync="is_loading_logs"
                :search-options="{
                enabled: false,
            }"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('logs.logs')}),
            }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.created_at'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.created_at).format('HH:mm DD-MM-YYYY') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.difficulty'">
                        <p v-if="props.row.attributes.difficulty">
                            {{ props.row.attributes.difficulty }}
                        </p>
                        <p v-else>No Difficulty Selected</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.buttons'">
                        <p v-if="props.row.attributes.buttons">
                            {{ props.row.attributes.buttons}}
                        </p>
                        <p v-else>No Buttons Recorded</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link tag="button" class="button --secondary --outline --mini --big-text"
                                     :to="{name: 'sublogs-index', params: {id: props.row.id}}"
                                     :content="$t('Sub Logs')" v-tippy="{ placement : 'top',  arrow: true }">
                            <font-awesome-icon :icon="['fal', 'clipboard-list-check']"/>
                        </router-link>
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasPermission('update logEntries')"
                                :onclick="()=>toggleUpdate(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasPermission('destroy logEntries')"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <download-c-s-v v-if="csv_data.length" v-show="false" ref="csv-generator" :data="csv_data"></download-c-s-v>

        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import LogsUpdateModal from "@/components/logs/LogsUpdateModal.vue";
import FormInputSelect from "@/components/form/FormInputSelect.vue";

export default {
    name: "logs-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect},
    data: function () {
        const columns = [
            {
                label: this.$t('logs.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('logs.name'),
                field: 'relationships.player.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('logs.email'),
                field: 'relationships.player.data.attributes.email',
                sortable: false,
            },
            {
              label: this.$t('logs.buttons'),
              field: 'attributes.buttons',
              sortable: false,
            },
            
            {
                label: this.$t('logs.game'),
                field: 'relationships.game.data.attributes.name',
                sortable: false,
            },
            {
              label: this.$t('logs.difficulty'),
              field: 'attributes.difficulty',
              sortable: false,
            },
            {
                label: this.$t('logs.score'),
                field: 'attributes.score',
                sortable: false,
            },
            {
                label: this.$t('logs.health'),
                field: 'attributes.health',
                sortable: false,
            },
            {
                label: this.$t('logs.message'),
                field: 'attributes.message',
                sortable: false,
            },
            {
                label: this.$t('logs.created'),
                field: 'attributes.created_at',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update logEntries', 'destroy logEntries']))
            columns.push({
                label: this.$t('users.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            logs: [],
            playerOptions: [],
            gameOptions: [],
            is_loading_logs: false,
            is_loading_players: false,
            is_loading_games: false,
            is_loading_logs_csv_data: false,
            totalRecords: null,
            serverParams: {
                filters: []
            },
            filter_player: null,
            filter_game: null,
            searchTerm: null,
            csv_data: [],
            csv_headers: ['Log', 'Name', 'Email', 'Buttons', 'Game', 'Difficulty', 'Score', 'Health', 'Message', 'Date Created'],
            is_deleting: false,
            is_printing: false,
            headbarExpanded: false
        }
    },
    methods: {
        generateDownload() {
            this.getLogsCSVData(this.exportCSV);
        },
        async generateCSVDownload() {
            this.is_printing = true;
    this.is_printing = false;
        },
        toggleUpdate(log) {
            this.$modal.show(
                LogsUpdateModal, {
                    log_id: log.id,
                },
                {
                    name: 'logs-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveLogs();
                    }
                }
            );
        },
        toggleDelete(log) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('logs.delete_log'),
                    message: this.$t('logs.prompt_delete'),
                    subtext: this.$t('logs.prompt_delete_subtext'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`logEntries/${log.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveLogs();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('logs.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveLogs();
        },
        onSortChange(params) {
            let sort_by = params[0].field.split('.')[1];
            this.updateParams({sort_by: sort_by, sort_order: params[0].type});
            this.retrieveLogs();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveLogs();
        },
        async retrieveLogs() {
    this.is_loading_logs = true;

    const term = this.serverParams.term;

    await this.$axios.get('logEntries', {
      params: {
        page: this.serverParams.page?? 1,
        term,
        filters: this.serverParams.filters,
        sort_by: this.serverParams.sort_by,
        sort_order: this.serverParams.sort_order
      }
    })
   .then(({ data }) => {
      this.logs = data.data;
      this.totalRecords = data.meta.total;
      this.is_loading_logs = false;
    })
   .catch(e => {
      this.is_loading_logs = false;

      this.$notify({
        title: this.$t('error'),
        text: this.$larerror(e.response.data, this.$t('logs.error_retrieve')),
        type: 'error',
      });
    });
  },
        onFilterUpdated() {
            const filters = [];

            if (this.filter_player)
                filters.push({filter_by: 'player_id', filter_value: this.filter_player.id});

            if (this.filter_game)
                filters.push({filter_by: 'game_id', filter_value: this.filter_game.id});

            if (filters.length)
                this.updateParams({
                    filters: filters
                });
            else this.removeParam('filters');
            console.log(filters);
            console.log("Text");

            this.retrieveLogs();
        },
        async retrievePlayers() {
            this.is_loading_players = true;

            await this.$axios.get('players/list')
                .then(({data}) => {
                    this.playerOptions = data.data;
                    this.is_loading_players = false;
                })
                .catch(e => {
                    this.is_loading_players = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('players.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveGames() {
            this.is_loading_games = true;

            await this.$axios.get('games/list')
                .then(({data}) => {
                    this.gameOptions = data.data;
                    this.is_loading_games = false;
                })
                .catch(e => {
                    this.is_loading_games = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('games.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getLogsCSVData(callback) {
  this.is_loading_logs_csv_data = true;
  this.csv_data = [];

  let page = 1;
  const pageSize = 100; // adjust this value to match the API's default page size
  let allLogs = [];

  const fetchLogs = () => {
    this.$axios.get('logEntries', {
      params: {
        page,
        size: pageSize,
      },
    })
   .then(({ data }) => {
      allLogs = [...allLogs,...data.data]; // Store all logs in the allLogs array

      // Check if there are more pages
      if (data.meta.total > allLogs.length) {
        page++;
        fetchLogs(); // Fetch the next page
      } else {
        // Process all logs once all pages have been fetched
        allLogs.forEach((log) => {
          this.csv_data.push({
            'Log': log.id,
            'Name': log.relationships.player.data.attributes.name,
            'Email': log.relationships.player.data.attributes.email,
            'Buttons': log.attributes.buttons,
            'Game': log.relationships.game.data.attributes.name,
            'Difficulty': log.attributes.difficulty,
            'Score': log.attributes.score,
            'Health': log.attributes.health,
            'Message': log.attributes.message,
            'Date Created': this.$moment(log.attributes.created_at).format('YYYY-MM-DD HH:mm'),
          });
        });

        this.is_loading_logs_csv_data = false; // All pages fetched, set loading to false

        callback(); // Call the callback function to export the CSV data
      }
    })
   .catch((e) => {
      this.is_loading_logs_csv_data = false;

      this.$notify({
        title: this.$t('error'),
        text: this.$larerror(e.response.data, this.$t('logs.error_retrieve')),
        type: 'error',
      });
    });
  };

  fetchLogs();
},

exportCSV() {
 const csvContent = `${this.csv_headers.map((header) => `${header}`).join(',')}\n${this.csv_data.map((row) => Object.values(row).join(',')).join('\n')}`; 
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'logs.csv';
  link.click();
},

    },
    async mounted() {
     
        await this.retrievePlayers();
        await this.retrieveGames();
        await this.retrieveLogs();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.logs')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {

    .filter-container {
        @apply flex flex-row gap-x-4 items-center w-full;

        @screen lg {
            @apply w-auto;
        }

        & > p {
            @apply text-primary font-bold text-xs;
        }

        .input-group {
            @apply w-36 mb-0 ml-auto;

            @screen lg {
                @apply ml-0;
            }
        }

        &.date-container {
            & > .input-group {
                @apply min-w-50;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
