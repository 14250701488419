<template>
  <div class="players-create-modal">
    <ModalContainer :title="$t('players.add_player')" identifier="players-create-modal" :closable="true">
      <Form class="form" @submit="save" :disabled="is_saving">
        <FormInputText v-model="$v.player.name.$model" ref="name" identifier="name" :label="$t('players.name')"
                       :disabled="is_saving" :has-error="$v.player.name.$error">
          <template v-slot:errors>
            <p v-if="!$v.player.name.required">
              {{ $t('validation.x_is_required', {x: $t('players.name')}) }}
            </p>
          </template>
        </FormInputText>
        <FormInputText v-model="$v.player.email.$model" identifier="email" :label="$t('players.email')"
                       :placeholder="$t('players.email')" :disabled="is_saving"
                       :has-error="$v.player.email.$error" autocomplete="off">
          <template v-slot:errors>
            <p v-if="!$v.player.email.required">
              {{ $t('validation.x_is_required', {x: $t('players.email')}) }}
            </p>
            <p v-else-if="!$v.player.email.email">
              {{ $t('auth.enter_valid_email') }}
            </p>
          </template>
        </FormInputText>

        <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
          {{ $t('save') }}
        </Button>
      </Form>
    </ModalContainer>
  </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
  name: "PlayersCreateModal",
  components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
  data() {
    return {
      player: {
        name: null,
        email: null,
      },
      is_saving: false,
    }
  },
  validations: {
    player: {
      name: {required},
      email: {required, email},
    }
  },
  methods: {
    close(status) {
      this.$modal.hide('players-create-modal', status);
    },
    save() {
      this.$v.player.$touch();
      if (this.$v.player.$anyError || this.is_saving)
        return;

      this.is_saving = true;

      const payload = Object.assign({}, this.$v.player.$model);

      this.$axios.post(`players`, payload).then(({data}) => {
        this.$notify({
          text: this.$t('players.success_created'),
          type: 'success',
        });

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('players.error_create')),
          type: 'error',
        });
      });
    },
  },
  mounted() {
    this.$refs.name.$el.children[1].focus();
  }
}
</script>
