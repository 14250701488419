<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Button className="--primary --small" :onclick="generateDownload"
                        :disabled="is_printing">
                    {{ $t('sublogs.export_sublogs') }}
                </Button>
                <Search class="search-desktop" :placeholder="$t('sublogs.search_sublogs')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('sublogs.search_sublogs')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="logs"
                :isLoading.sync="is_loading_logs"
                :search-options="{
                enabled: false,
            }"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                dropdownAllowAll: false,
                perPage: 15,
                perPageDropdownEnabled: false,
                rowsPerPageLabel: $t('x_per_page', {x: $t('logs.logs')}),
            }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.created_at'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.created_at).format('HH:mm DD-MM-YYYY') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="$store.getters.hasPermission('destroy logEntries')"
                                :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <download-c-s-v v-if="csv_data.length" v-show="false" ref="csv-generator" :data="csv_data"></download-c-s-v>

        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import LogsUpdateModal from "@/components/logs/LogsUpdateModal.vue";
import FormInputSelect from "@/components/form/FormInputSelect.vue";

export default {
    name: "logs-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect},
    data: function () {
        const columns = [
            {
                label: this.$t('sublogs.id'),
                field: 'attributes.log_entry_id',
                sortable: false,
            },
            {
                label: this.$t('logs.message'),
                field: 'attributes.message',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update logEntries', 'destroy logEntries']))
            columns.push({
                label: this.$t('users.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            logs: [],
            playerOptions: [],
            gameOptions: [],
            is_loading_logs: false,
            is_loading_players: false,
            is_loading_games: false,
            is_loading_sublogs_csv_data: false,
            totalRecords: null,
            serverParams: {
                filters: []
            },
            filter_player: null,
            filter_game: null,
            searchTerm: null,
            csv_data: [],
            is_deleting: false,
            is_printing: false,
            headbarExpanded: false
        }
    },
    methods: {
        generateDownload() {
            this.generateCSVDownload();
        },
        async generateCSVDownload() {
            this.is_printing = true;
            await this.$refs['csv-generator'].generate();
            this.is_printing = false;
        },
        toggleUpdate(log) {
            this.$modal.show(
                LogsUpdateModal, {
                    log_id: log.id,
                },
                {
                    name: 'logs-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveSubLogs();
                    }
                }
            );
        },
        toggleDelete(log) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('sublogs.delete_sublog'),
                    message: this.$t('sublogs.prompt_delete'),
                    subtext: this.$t('sublogs.prompt_delete_subtext'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`subLogEntries/${log.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveSubLogs();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('sublogs.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveSubLogs();
        },
        onSortChange(params) {
            let sort_by = params[0].field.split('.')[1];
            this.updateParams({sort_by: sort_by, sort_order: params[0].type});
            this.retrieveSubLogs();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveSubLogs();
        },
        async retrieveSubLogs() {
            this.getSubLogsCSVData();

            this.is_loading_logs = true;

            const term = this.serverParams.term;

            await this.$axios.get(`subLogEntries/logEntry/${this.$route.params.id}`, {
                params: {
                    page: this.serverParams.page ?? 1,
                    term,
                    filters: this.serverParams.filters,
                    sort_by: this.serverParams.sort_by,
                    sort_order: this.serverParams.sort_order
                }
            })
                .then(({data}) => {
                    this.logs = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_logs = false;
                    console.log(this.filters);
                })
                .catch(e => {
                    this.is_loading_logs = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('sublogs.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getSubLogsCSVData(callback) {
  this.is_loading_sublogs_csv_data = true;
  this.csv_data = [];

  let page = 1;
  const pageSize = 100; // adjust this value to match the API's default page size
  let allSubLogs = [];

  const fetchSubLogs = () => {
    this.$axios.get(`subLogEntries/logEntry/${this.$route.params.id}`, {
      params: {
        page,
        size: pageSize,
        ...this.serverParams,
      },
    })
    .then(({ data }) => {
      allSubLogs = [...allSubLogs, ...data.data]; // Store all sublogs in the allSubLogs array

      // Check if there are more pages
      if (data.meta.total > allSubLogs.length) {
        page++;
        fetchSubLogs(); // Fetch the next page
      } else {
        // Process all sublogs once all pages have been fetched
        allSubLogs.forEach((sublog) => {
          this.csv_data.push({
            'Parent Log ID': sublog.attributes.log_entry_id,
            'Message': sublog.attributes.message,
          });
        });

        this.is_loading_sublogs_csv_data = false; // All pages fetched, set loading to false

        callback(); // Call the callback function to export the CSV data
      }
    })
    .catch((e) => {
      this.is_loading_sublogs_csv_data = false;

      this.$notify({
        title: this.$t('error'),
        text: this.$larerror(e.response.data, this.$t('sublogs.error_retrieve')),
        type: 'error',
      });
    });
  };

  fetchSubLogs();
},
        onFilterUpdated() {
            const filters = [];
        },
    },
    async mounted() {
        await this.retrieveSubLogs();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.sublogs')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {

    .filter-container {
        @apply flex flex-row gap-x-4 items-center w-full;

        @screen lg {
            @apply w-auto;
        }

        & > p {
            @apply text-primary font-bold text-xs;
        }

        .input-group {
            @apply w-36 mb-0 ml-auto;

            @screen lg {
                @apply ml-0;
            }
        }

        &.date-container {
            & > .input-group {
                @apply min-w-50;
            }
        }
    }

    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
